// extracted by mini-css-extract-plugin
export var titleconnect = "contact-module--titleconnect--2d209";
export var titlestayconnected = "contact-module--titlestayconnected--dd449";
export var socialiconholder = "contact-module--socialiconholder--8c16d";
export var socialiconitem = "contact-module--socialiconitem--d9659";
export var companytitle = "contact-module--companytitle--a1d52";
export var registeredicon = "contact-module--registeredicon--c41c1";
export var oursalesofficetitle = "contact-module--oursalesofficetitle--88b50";
export var hotlinesection = "contact-module--hotlinesection--5403b";
export var sectiontitle = "contact-module--sectiontitle--64826";
export var detailsarea = "contact-module--detailsarea--e0f3d";
export var detailsfontsize = "contact-module--detailsfontsize--9da88";
export var detailsholderrow = "contact-module--detailsholderrow--e8cf1";
export var detailsholder = "contact-module--detailsholder--5f1ae";
export var detailsholderright = "contact-module--detailsholderright--4a1c6";
export var detailsholderbottom = "contact-module--detailsholderbottom--442f5";
export var contactdetailitems = "contact-module--contactdetailitems--2869b";
export var otherforminfoholder = "contact-module--otherforminfoholder--969d1";
export var otherformfieldholder = "contact-module--otherformfieldholder--6a09e";
export var contactdetailwebsite = "contact-module--contactdetailwebsite--edead";
export var contactdetailemail = "contact-module--contactdetailemail--7dd8b";
export var contactdetailmobile = "contact-module--contactdetailmobile--f5527";
export var contactdetailtelefax = "contact-module--contactdetailtelefax--1e19b";
export var contactdetailfax = "contact-module--contactdetailfax--a680c";
export var contactdetaillandline = "contact-module--contactdetaillandline--4eb0b";
export var contactdetailaddress = "contact-module--contactdetailaddress--d826c";
export var detailsmaintitle = "contact-module--detailsmaintitle--643f6";
export var contactdetail = "contact-module--contactdetail--2e922";
export var contactdetailtitle = "contact-module--contactdetailtitle--6f607";
export var contactformholder = "contact-module--contactformholder--ae477";