
function parseJSON(response) {
	return new Promise(resolve => {
		response.text().then(body => {
			resolve({
				status: response.status,
				ok: response.ok,
				json: body !== '' ? JSON.parse(body) : '{}'
			})
		})
	})
}

const savefile = (outname, url, method, body = undefined, jwtToken = undefined, fail=false) => {
	if (method.toUpperCase() === "GET") {
		return get(url, jwtToken);
	}
	const promise = new Promise((resolve, reject) => {
		const req = () => {
			const headers = {
				'Content-Type': 'application/json'
			}
			
			if(jwtToken!==undefined) {
				headers.Authorization = jwtToken
			}
			fetch(`${url}`, {
				method: method.toUpperCase(),
				headers,
				body: `${JSON.stringify(body)}`,
			})
			.then((response) => response.blob())
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					outname,
				);
				// Append to html link element page
				document.body.appendChild(link);
			
				// Start download
				link.click();
			
				// Clean up and remove the link
				link.parentNode.removeChild(link);

				resolve({status: "OK"})
			})
			.catch( (error) => {
				resolve({status: "Error"})
				//reject( error ) 
			})
		}
		return req();
	})

	return promise
}

const send = (url, method, body = undefined, jwtToken = undefined, fail=false) => {
	if (method.toUpperCase() === "GET") {
		return get(url, jwtToken);
	}
	const promise = new Promise((resolve, reject) => {
		const req = () => {
			const headers = {
				'Content-Type': 'application/json'
			}
			
			if(jwtToken!==undefined) {
				headers.Authorization = jwtToken
			}
			fetch(`${url}`, {
				method: method.toUpperCase(),
				headers,
				body: `${JSON.stringify(body)}`,
			})
			.then(parseJSON)
			.then(response => {
				if (response.ok) {
					resolve(response.json)
				}
				resolve(response.json)
				//reject(response.json)
			})
			.catch( (error) => {
				resolve({status: "Error"})
				//reject( error ) 
			})
		}
		return req();
	})

	return promise
}

const get = (url, jwtToken = undefined) => {
	const promise = new Promise((resolve, reject) => {
		const req = () => {
			var options = {
				method: "GET"
			};
			if(jwtToken!==undefined) {
				options.headers = {
					"Authorization": jwtToken
				};
			}
				
			fetch(url, options)
			.then(parseJSON)
			.then(response => {
				if (response.ok) {
					resolve(response.json)
				}
				reject(response.json)
			})
			.catch(error => reject( "errors: " + error))
		}
		return req();
	})

	return promise
	
}

const formGraphQL = (url, query) => {
	return {
			url: url, 
			method: "POST",
			param: {
				query: query
			}
		}
}


module.exports = {
	formGraphQL,
	savefile,
	send,
	get
}

