import React from "react"

import Layout from "../../../components/layout"
import ContactForm from "../../../components/contactform"


import * as styles from "../../../styles/pages/about/contact.module.css"


const contactfields = [
	{
		"name": "formname",
		"label": "Name",
		"value": "",
		"required": true,
	},
	{
		"name": "formlocation",
		"label": "Address",
		"value": "",
		"type": "text",
		"required": true,
	},
	{
		"name": "formemail",
		"label": "Email",
		"type": "email",
		"value": "",
		"required": true,
	},
	{
		"name": "formphone",
		"label": "Contact No.",
		"type": "tel",
		"value": "",
		"required": true,
	}
];


const DealerPage = () => {

	return (<Layout activePath='about/contact/dealer' pageTitle="Locate a Dealer" pageDescription="To serve you best, we will email you the dealers closest to your address.">
		<div className="text-centered">
			<h1 className={"textcolor-primary"}>
			Locate a Dealer
			</h1>
			<div className={"textcolor-primary "+styles.otherforminfoholder}>
				<div>To serve you best,</div> we will email you the dealers closest to your address.
			</div>
			<div className={styles.contactformholder+" "+styles.otherformfieldholder}>
				<ContactForm
					submitURL={"https://api.boysencdn.com/contact/boysen/dealer"}
					fields={contactfields}
				/>
			</div>
		</div>
	</Layout>);
}


export default DealerPage
